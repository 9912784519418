import { api } from "@/api/list";
export function saveAs(id, filename?:string) {
  const link = document.createElement("a");
  const body = document.querySelector("body");
  link.href = api['downloadUrl'](id);
  if(filename){
    link.download = filename;
  }
  // fix Firefox
  link.style.display = "none";
  body?.appendChild(link);
  link.click();
  body?.removeChild(link);
  window.URL.revokeObjectURL(link.href);
}
